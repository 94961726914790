import "./ButtonLearnMore.css";
import { useNavigate, Link } from "react-router-dom";
import {
  useEffect
} from 'react'

const ButtonLearnMore = () => {

  useEffect(() => {
    function handleScroll() {
      const element = document.querySelector(".whatsapp-logo6d974d7esvg-fil-icon");
      const elementAI = document.querySelector(".TrendAI-Logo");
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement;

      if (element && elementAI) {
        if (scrollHeight - scrollTop === clientHeight) {
          // Scroll has reached the end
          console.log("Scroll reached the end");
          element.style.bottom = "75%";
          elementAI.style.bottom = "70%";
        } else {
          element.style.bottom = "-1%";
          elementAI.style.bottom = "15%";

        }
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  const navigate = useNavigate();
  return (
    <div className="button-learn-more">
      <div className="user-guide-img">
        <div className="divrow17">
          <img
            className="landmarks570e638bpng-icon"
            alt=""
            src="/landmarks570e638bpng@2x.png"
          />
          <div className="contact-us-button">
            <div className="divrow-paints">
              <b className="heading-1-container3">
                <span className="heading-1-container4">
                  <p className="make-the-switch">Make the switch, and stay</p>
                  <p className="connected-at-all">connected at all times</p>
                </span>
              </b>
              <button className="button29" onClick={() => {
                navigate("/plans")
              }}>
                <b className="view-all-plans">View all plans</b>
              </button>
              <img
                className="car0f709f7cgif-icon"
                loading="eager"
                alt=""
                src="/car0f709f7cgif@2x.png"
              />
              <div className="divmousey">
                <div className="divscroller" />
              </div>
              <img
                className="whatsapp-logo6d974d7esvg-fil-icon"
                loading="eager"
                alt=""
                src="/whatsapplogo6d974d7esvg4@2x.png"

                onClick={() => {
                  window.open("https://api.whatsapp.com/send/?phone=447482774201")

                }}
              />
              <Link to="/trend-AI">
                <img
                  className="TrendAI-Logo"
                  alt=""
                  src="/chatbot.png"
                  title="Contact Our AI"
                />
              </Link>
            </div>
            <div className="wrapper-divcol-md-12">
              <img className="divcol-md-12-icon" alt="" src="/divcolmd12.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ButtonLearnMore;
