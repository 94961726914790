import "./Term&Condition.css";
import Navigation1 from "../components/Navigation1";
import Divfooter1 from "../components/Divfooter1";
import {
    FaSquareFacebook,
    FaSquareInstagram,
    FaSquareYoutube,
    FaTiktok,
    FaSquarePinterest
} from "react-icons/fa6";
import { useEffect } from "react";
import { Link } from "react-router-dom";

function PrivacyPolicy() {

    useEffect(() => {
        function handleScroll() {
            const element = document.querySelector(".whatsapp-logo6d974d7esvg-icon");
            const elementAI = document.querySelector(".TrendAI-Logo");
            const { scrollHeight, scrollTop, clientHeight } = document.documentElement;

            if (element && elementAI) {
                if (scrollHeight - scrollTop === clientHeight) {
                    // Scroll has reached the end
                    console.log("Scroll reached the end");
                    element.style.bottom = "75%";
                    elementAI.style.bottom = "70%";
                } else {
                    element.style.bottom = "-1%";
                    elementAI.style.bottom = "15%";

                }
            }
        }

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);
    return (
        <div className="term-condition">
            <Navigation1 />
            <main className="footer-frame-1">
                <div className="header-texts">
                    <h1 className="heading-2">Privacy Policy</h1>
                </div>
                <section className="registration-texts" style={{
                    textAlign: "center",
                }}>
                    <div className="account-activation">
                        <div className="monty-uk-global-container-1">
                            <span className="monty-uk-global-container1">
                                <p className="monty-uk-global">
                                    By accessing or using TrendeSIM, you agree to the use of information that you have provided when you registered to the service in order to keep in contact with you and seek your feedback.
                                </p>
                                <p className="bridge-house181-queen">
                                    We are committed to keep your personal information secure and protected. A variety of industry-standard security technologies and procedures are used in order to protect your personal information from unauthorized access, usage or disclosure. However, no security measure is totally secure, and we cannot guarantee the security of your information.
                                </p>
                            </span>
                        </div>
                        <div className="please-take-some-container">
                            <p className="please-take-some">
                                <br />
                                By accessing or using TrendSIM, you agree to the use of information that you have provided when you registered to the service in order to keep in contact with you and seek your feedback. We may use the information later to inform you about any future services that we may offer. At any time, you can ask us to adjust or delete the information you have previously shared with us. In addition, we automatically collect information about how you have used TrendeSIM, your devices operating systems, IP address, etc. We analyze this information to troubleshoot any issues you might.
                            </p>
                            <p className="no-agency-partnership">
                                encounter, to work out how our products are being used and how to improve them. <br />
                                This Privacy Policy is subject to occasional revision, and if we make any changes in the way we use your personal information, we will send you a notification. Please note that, at all times, you are responsible for updating your personal information.
                            </p>
                        </div>
                        <img
                            className="whatsapp-logo6d974d7esvg-icon"
                            loading="eager"
                            alt=""
                            src="/whatsapplogo6d974d7esvg1@2x.png"
                            onClick={() => {
                                window.open("https://api.whatsapp.com/send/?phone=447482774201")

                            }}
                        />
                        <Link to="/trend-AI">
                            <img
                                className="TrendAI-Logo"
                                alt=""
                                src="/chatbot.png"
                                title="Contact Our AI"
                            />
                        </Link>
                    </div>
                </section>
            </main>
            <Divfooter1 />
            <div className="divfooter-sub3">
                <div className="qr-curves-b-s-v-g">
                    <Link style={{
                        textDecoration: "none",
                        color: "inherit"
                    }}
                        to="/privacy-policy"
                    > <b >Privacy Policy</b></Link>
                    <Link style={{
                        textDecoration: "none",
                        color: "inherit"
                    }}
                        to="/refund-policy"
                    > <b >Refund Policy</b></Link>
                    <Link style={{
                        textDecoration: "none",
                        color: "inherit"
                    }}
                        to="/term-condition"
                    > <b >Terms & Conditions</b></Link>


                    <div className="social-media">
                        <b >Contact Us</b>

                        <a href="https://www.facebook.com/people/Trendesim-English/61558743480892/" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquareFacebook />
                        </a>
                        <a href="https://www.instagram.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquareInstagram />
                        </a>
                        <a href="https://www.youtube.com/@TrendeSIM" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquareYoutube />
                        </a>
                        <a href="https://www.tiktok.com/@trendesim" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaTiktok />
                        </a>
                        <a href="https://www.pinterest.com/trendesim/" className="icon-links" target="_blank" rel="noopener noreferrer">
                            <FaSquarePinterest />
                        </a>

                    </div>
                </div>
                <b>© 2024 Trend eSIM</b>
            </div>
        </div>
    )
}

export default PrivacyPolicy