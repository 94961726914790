import CountryFlags from "../components/CountryFlags";
import RegionsFlags from "../components/RegionFlags";
import Global from "../components/global";
import Divfooter4 from "../components/Divfooter4";
import DivfooterSub from "../components/DivfooterSub";
import "./WwwmontyesimcomByHtmltod4.css";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import Navigation1 from "../components/Navigation1";
import { useState, useEffect } from "react";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import axios from 'axios';
import { Buffer } from "buffer";
import { countries } from 'country-data';

const animatedComponents = makeAnimated();

const WwwmontyesimcomByHtmltod4 = () => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [Hide, setHide] = useState(false);
  const [data, setData] = useState([]);
  const [search, setsearch] = useState([]);
  const [countryOptions, setcountryOptions] = useState([])
  const apiUrl = process.env.REACT_APP_API_URL;
  const username = process.env.REACT_APP_USERNAME_ESIM;
  const password = Buffer.from(process.env.REACT_APP_PASSWORD_ENCODE_ESIM, 'base64').toString();
  const dataBody = JSON.stringify({
    username,
    password
  });
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [defaultValueOptions, setdefaultValueOptions] = useState([]);
  useEffect(() => {
    const getCountries = async () => {
      const conf = {
        method: 'post',
        url: apiUrl + 'Countries',
        headers: {
          'Content-Type': 'application/json'
        },
        data: dataBody
      }
      try {
        const result = await axios(conf);
        setData(result.data);
        setcountryOptions(result.data.countries.map((item) => ({
          value: item.iso3_code,
          label: item.country_name
        })));
      } catch (error) {
        console.error(`Error occurred while fetching data: ${error}`);
      }

    };
    getCountries();

  }, []);

  useEffect(() => {
    const tabs = document.querySelectorAll('.tab-1');

    if (location.pathname === "/plans") {
      tabs.forEach(btn => {
        btn.classList.remove('t-r-s-v-g');
        btn.classList.add('button1');
      });
      tabs[0].classList.remove('button1');
      tabs[0].classList.add('t-r-s-v-g');
    } else if (location.pathname === "/plans/region-esim") {
      tabs.forEach(btn => {
        btn.classList.remove('t-r-s-v-g');
        btn.classList.add('button1');
      });
      tabs[1].classList.remove('button1');
      tabs[1].classList.add('t-r-s-v-g');

    } else if (location.pathname === "/plans/global-esim") {
      tabs.forEach(btn => {
        btn.classList.remove('t-r-s-v-g');
        btn.classList.add('button1');
      });
      tabs[2].classList.remove('button1');
      tabs[2].classList.add('t-r-s-v-g');

    }
    else if (location.pathname.includes("-esim") && selectedOptions.length === 0) {
      setIsLoading(true);
      setHide(true);
      toggleClass();

      setTimeout(async () => {
        let NameOfCountry = location.pathname.split("/")[2].replace("-esim", "").replace(/-/g, " ");
        if (NameOfCountry === "Aland Islands") {
          NameOfCountry = "Åland Islands";
        }
        const iso3_code = countries.all.find(country => country.name === NameOfCountry) ? countries.all.find(country => country.name === NameOfCountry).alpha3 : false;
        if (!iso3_code) {
          navigate("/plans");
          // return;
        }
        setSelectedOptions([{ value: iso3_code, label: NameOfCountry }]);
        const fetchPathBundles = async () => {
          const conf = {
            method: 'post',
            url: apiUrl + 'AllCountries',
            headers: {
              'Content-Type': 'application/json'
            },
            data: dataBody,
            params: {
              countries_req: [iso3_code]
            }
          }
          const result = await axios(conf);
          setsearch(result.data);
        };
        await fetchPathBundles();
        setIsLoading(false);
        setHide(false);
      }, 300);

      // setTimeout(() => {
      //   setHide(false);
      // }, 3500);
    }
    else if (location.pathname.includes("-esim") && selectedOptions.length > 0) {
      tabs.forEach(btn => {
        btn.classList.remove('t-r-s-v-g');
        btn.classList.add('button1');
      });
      tabs[0].classList.remove('button1');
      tabs[0].classList.add('t-r-s-v-g');
    }
  }, [location]);

  const handleChange = async (options) => {
    if (options && options.length > 3) {
      return;
    }
    setIsLoading(true);
    if (search.length === 0) {
      setHide(true);
    }
    setSelectedOptions(options);
    const countryNames = options.map(option => option.value);
    const conf = {
      method: 'post',
      url: apiUrl + 'AllCountries',
      headers: {
        'Content-Type': 'application/json'
      },
      data: dataBody,
      params: {
        countries_req: countryNames
      }
    }

    if (countryNames.length > 0) {
      try {
        const result = await axios(conf);
        setsearch(result.data);
        setIsLoading(false);
        if (result.data.length === 1) navigate(`/plans/${result.data[0].country_name.replace(/ /g, "-")}-esim`);
        if (result.data.length > 1) navigate("/plans");
        // console.log(result.data);
      } catch (error) {
        console.error(`Error occurred while fetching data: ${error}`);
        // setIsPending(false);
      }
    }
    else {
      setsearch([]);
      navigate("/plans");
    }
    setHide(false);
  };
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    function handleScroll() {
      const element = document.querySelector(".whatsapp-logo6d974d7esvg-icon6");
      const elementAI = document.querySelector(".TrendAI-Logo");
      const { scrollHeight, scrollTop, clientHeight } = document.documentElement;

      if (element && elementAI) {
        if (scrollHeight - scrollTop === clientHeight) {
          // Scroll has reached the end
          // console.log("Scroll reached the end");
          element.style.bottom = "75%";
          elementAI.style.bottom = "70%";
        } else {
          element.style.bottom = "-1%";
          elementAI.style.bottom = "15%";

        }
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  return (
    <div className="wwwmontyesimcom-by-htmltod5">
      <Navigation1 />
      <main className="search-frame">
        <div className="button-group-frame">
          <div className="country-region-frame">
            <div className="popular-countries-frame">
              <div className="row-frame-with-cards">
                <h1 className="heading-12">Choose your plan</h1>
              </div>
              <div className="footer-frame">
                <div className="please">{`Please `}</div>
                <b className="dial-06">dial *#06#</b>
                <div className="check-device-comp">
                  <div className="your-device">
                    <div className="to-check-device">
                      to check device compatibility, if EID exist then
                    </div>
                  </div>
                  <div className="your-device-is">
                    your device is compatible
                  </div>
                </div>
              </div>
            </div>
            <div className="nav-link-frame">
              <div className="buttons-frame">
                <div className={isActive ? 'searchicon-active' : 'searchicon'} >
                  <img
                    className="searchicon5548a86csvg"
                    loading="eager"
                    alt=""
                    src="/searchicon5548a86csvg.svg"
                    onClick={toggleClass}
                  />
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    // defaultValue={defaultValueOptions}
                    isMulti
                    options={countryOptions}
                    value={selectedOptions}
                    onChange={handleChange}
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        width: '100%',
                        height: '100%',
                        background: 'transparent',
                        borderRadius: '25px',
                        border: 'none',
                        outline: 'none',
                        boxShadow: state.isFocused ? 0 : 0, // this will remove the blue border
                        '&:hover': {
                          border: 'none', // this will remove the border on hover
                        }
                      }),
                    }}
                    className="input"
                  />
                </div>
                <div className="divtabs-plans">

                  <button className="t-r-s-v-g tab-1" onClick={(e) => {
                    if (search.length === 1) navigate(`/plans/${search[0].country_name.replace(/ /g, "-")}-esim`);
                    else navigate("/plans");
                  }}>
                    <b className="countries">
                      Countries
                    </b>
                  </button>


                  <button className="button1 tab-1" onClick={(e) => {
                    navigate("/plans/region-esim");
                  }}>
                    <b className="regions">Regions</b>
                  </button>


                  <button className="button1 tab-1" onClick={(e) => {
                    navigate("/plans/global-esim");
                  }}>
                    <b className="global">Global</b>
                  </button>

                </div>
              </div>
            </div>
          </div>
        </div>
        {Hide && <div className="country-flags1" style={{ height: "200px", alignItems: "center" }}><div className="loader"></div></div>}
        {!Hide && (search.length === 0) && <Routes>
          <Route path="" element={<CountryFlags isHome={false} />} />
          <Route path="region-esim" element={<RegionsFlags />} />
          <Route path="global-esim" element={<Global />} />
          {/* <Route path="*" element={<Navigate to="/plans" />} /> */}
        </Routes>}
        
        {!Hide && (search.length === 1) && <Routes>
          <Route path={search[0].country_name.replace(/ /g, "-") + "-esim"}>
            <Route index element={<Global search={search} isLoading={isLoading} />} />
            <Route path="1GB" element={<Global search={search} isLoading={isLoading} />} />
            <Route path="3GB" element={<Global search={search} isLoading={isLoading} />} />
            <Route path="5GB" element={<Global search={search} isLoading={isLoading} />} />
            <Route path="10GB" element={<Global search={search} isLoading={isLoading} />} />
            <Route path="15GB" element={<Global search={search} isLoading={isLoading} />} />
            <Route path="20GB" element={<Global search={search} isLoading={isLoading} />} />
            <Route path="30GB" element={<Global search={search} isLoading={isLoading} />} />
            <Route path="50GB" element={<Global search={search} isLoading={isLoading} />} />
          </Route>
          {/* <Route path={search[0].country_name.replace(/ /g, "-") + "-esim/*"} element={<Global search={search} isLoading={isLoading} />} /> */}
          {/* <Route path={search[0].country_name.replace(/ /g, "-") + "-esim/:pathParam"} element={<CustomRouteComponent search={search} isLoading={isLoading} />} /> */}
          <Route path="region-esim" element={<Global search={search} />} />
          <Route path="global-esim" element={<Global search={search} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>}
        {!Hide && (search.length > 1) && <Routes>
          <Route path="" element={<Global search={search} isLoading={isLoading} />} />
          <Route path="region-esim" element={<Global search={search} />} />
          <Route path="global-esim" element={<Global search={search} />} />
          {/* <Route path="*" element={<Navigate to="/plans" />} /> */}
        </Routes>}
        <Divfooter4 />
        <DivfooterSub />
      </main>
    </div>
  );
};

export default WwwmontyesimcomByHtmltod4;
